import Modal from 'components/Modal';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Styles from '../styles';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import Button from "components/Button";
import { gql, useMutation } from '@apollo/client';
import { launchToast } from 'shared/utils/launchToast';
import { GET_OBSERVATIONS } from '..';
import  { editPetObservationFormId } from './form/EditUserObservationForm';
import { Form } from "@unform/web";
import Select from "components/Select";
import TextArea from "components/TextArea";
import { Label } from "components/Label/styles";
import { NewUserObservationFormValidations } from './newUserObservationFormValidations';
import { FormHandles } from '@unform/core';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';


type IEditObservationModalProps = {
    isOpen: boolean;
    onClose: () => void;
    observation: {
        id: string
        description: string
        added_by: string
        show_to_customer: boolean
        created_at: string
        pet_id?: string,
        user_id?: string
    }
};

export type IEditObservationPayload = {
    id: string;
    observation: string;
    showToCustomer: boolean;
    observationTo: string;
  };

const EDIT_APPOINTMENT_OBSERVATION = gql`
    mutation EditPermanentObservation($input: EditPermanentObservationInput!) {
        editPermanentObservation(input: $input) {
            petOrCustomerId
            id
            description
            added_by
            show_to_customer
            created_at
        }
    }
`;

const EditAppointmentObservationModal: React.FC<IEditObservationModalProps> = ({ isOpen, onClose, observation}) => {

    const [showToCustomer, setShowToCustomer] = useState([] as any);
    const [showToCustomerError, setShowToCustomerError] = useState("");
    const [observatioText, setObservationText] = useState([] as any);
    const editAppointmentObservatiionFormRef = useRef<FormHandles>(null);

    const showToCustomerOptions = [
        { label: "Mostrar", value: true },
        { label: "Não mostrar", value: false },
      ];

    const [editPermanentObservation, { loading }] = useMutation(EDIT_APPOINTMENT_OBSERVATION, {
        refetchQueries: [
            GET_OBSERVATIONS,
            'GetPermanetObservations'
        ],
    });

    const handleSubmit = useCallback(
        async () => {
            try {
                await editPermanentObservation({
                    variables: {
                        input: {
                            id: observation.id,
                            observation: observatioText,
                            showToCustomer: showToCustomer.value,
                            observationTo: observation.pet_id ? "pet" : "customer"
                        }
                    },
                });
                onClose();
                launchToast('Observação editada com sucesso!', 'success');
                resetForm();
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [editPermanentObservation, showToCustomer, observation, observatioText],
    );

    const validateFields = async () => {
        try {
          await NewUserObservationFormValidations.validate(
            {
                id: observation.id,
                observation: observatioText,
                showToCustomer: showToCustomer.value,
                observationTo: observation.pet_id ? "pet" : "customer"
            },
            { abortEarly: false }
          );
          await handleSubmit()
        } catch (error) {
          const errors = getValidationErrors(error as ValidationError);
          editAppointmentObservatiionFormRef.current?.setErrors(errors);
          if (!showToCustomer.label) {
            setShowToCustomerError("Selecione uma opção");
          }
          launchToast("Verifique o preenchimento dos dados", "error");
        }
      };

      useEffect(() => {
        setObservationText(observation.description)
        editAppointmentObservatiionFormRef.current?.setErrors({});
        observation.show_to_customer
          ? setShowToCustomer({ label: "Mostrar", value: true })
          : setShowToCustomer({ label: "Não mostrar", value: false });
        setShowToCustomerError("");
      }, [observation]);
    
      const resetForm = () => {
        setShowToCustomerError("");
        setObservationText("")
        editAppointmentObservatiionFormRef.current?.setErrors({});
        setShowToCustomer([] as any);
      };

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.header>
                <Text
                    text={'Editar observação'}
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
            </Styles.header>
            <Styles.FormDiv>
                <Form
                    onSubmit={validateFields}
                    ref={editAppointmentObservatiionFormRef}
                >
                    <Styles.row>
                    <Styles.field>
                        <Label>Exibir para o cliente</Label>
                        <Select
                            name="showToCustomer"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione a ação"
                            options={showToCustomerOptions}
                            value={showToCustomer}
                            error={showToCustomerError}
                            onChange={(e: any) => setShowToCustomer(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Observação</Label>
                        <TextArea
                            containerStyle={{ width: "40rem" }}
                            name="observation"
                            placeholder="Digite a observação"
                            value={observatioText}
                            onChange={(event) => setObservationText(event.target.value)}
                        />
                    </Styles.field>
                    </Styles.row>
                </Form>
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button text="Salvar" loading={loading} onClick={() => validateFields()}/>
            </Styles.rowButton>
        </Modal>
    )
}

export default EditAppointmentObservationModal