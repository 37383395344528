import { format, parseISO } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as CancelButton } from "../../assets/svg/generic/cancelbutton.svg";
import { ReactComponent as Aprove } from "../../assets/svg/generic/checked.svg";
import { ReactComponent as EditButton } from "../../assets/svg/generic/edit.svg";
import { ReactComponent as Link } from "../../assets/svg/generic/link.svg";
import { ReactComponent as MoneyOrange } from "../../assets/svg/generic/money-orange.svg";
import { ReactComponent as MoneyGreen } from "../../assets/svg/generic/money-green.svg";
import { FiAlertCircle } from "react-icons/fi";
import FilterList from "../../components/FilterList";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import api from "../../services/api";
import RefundTypeEnum from "../../shared/enums/RefundTypeEnum";
import convertToCurrencyFormat from "../../shared/utils/convertToCurrencyFormat";
import PlatformEnum from "../../shared/utils/enums/PlatformEnum";
import StatusTransactionEnum from "../../shared/utils/enums/StatusTransactionEnum";
import TypePaymentEnum from "../../shared/utils/enums/TypePaymentEnum";
import { getActualDate } from "../../shared/utils/getActualDate";
import { launchToast } from "../../shared/utils/launchToast";
import masks from "../../shared/utils/masks";
import processError from "../../shared/utils/processError";
import removeSymbolsOfString from "../../shared/utils/removeSymbolsOfString";
import stringOfEnum from "../../shared/utils/StringOfEnum";
import IOrder from "../../types/IOrder";
import ITransaction from "../../types/ITransaction";
import ChooseRefundTypeModal from "../../components/ChooseRefundTypeModal";
import FilterModal from "./FilterModal";
import {
  approveOrder,
  cancelOrder,
  changePaymentTypeOfOrder,
  refundPendingValues,
  searchOrders,
} from "./graphQL";
import { PanelHeader } from "components/PanelHeader";
import { Search } from "components/Search";
import Table from "components/Table";
import { headers } from "./utils/columns";
import { ModalConfirmation } from "components/ModalConfirmation";
import { useDisclosure } from "@chakra-ui/react";
import { toast } from "react-toastify";
import { useAuth } from "hooks/Auth";
import { ReactComponent as SearchIcon } from "../../assets/svg/generic/searchicon.svg";
import OrderDetailsModal from "./modals/orderDetails";
import { IFormattedOrder } from "shared/utils/formatOrder";
import MainContainer from "components/MainContainer";
import ChoosePaymentPendingModal from "./ChoosePaymentPendingModal";
import paymentApi from "services/payment-api";
import LinkModal from "./modals/LinkModal";

const actualDate = getActualDate();

const Orders: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [loadingRefundValue, setLoadingRefundValue] = useState(false);
  const [modalVisible, setModalVisible] = useState<
    | "none"
    | "filtering"
    | "refunding"
    | "edit_order"
    | "change_payment"
    | "choose_payment_pending_values"
    | "cancel_order"
  >("none");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const linkModal = useDisclosure();
  const { user } = useAuth();

  const [filters, setFilters] = useState([
    { key: "created", searchValue: actualDate },
  ] as any);
  const [selectedOrder, setSelectedOrder] = useState<IOrder | undefined>(
    undefined
  );
  const [limit, setLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sort, setSort] = useState({} as any);
  const [wordSearch, setWordSearch] = useState("");
  const [timer, setTimer] = useState(null as any);
  const [linkText, setLinkText] = useState<string>("");
  const [selectedOrderDetails, setSelectedOrderDetails] = useState<
    IFormattedOrder | undefined
  >(undefined);

  const applyMask = useCallback((value: string, mask?: string) => {
    return (value = mask ? masks[mask](value) : value);
  }, []);

  const orderDetailsModalControl = useDisclosure();

  const formatOrder = (order: IOrder) => {
    const {
      id,
      created_at,
      platform,
      customer,
      appointments,
      transactions,
      status,
      total_discount,
      sales_order,
      pending_price,
    } = order;

    const { user } = customer;

    const actions = [
      {
        name: "Ver pedido",
        action: handleSeeOrderDetails,
        icon: <SearchIcon />,
      },
    ];

    if (status === StatusTransactionEnum.waiting) {
      actions.push({
        name: "Editar",
        action: handleEdit,
        icon: <EditButton />,
      });
      if (
        transactions.length &&
        transactions[0].type !== TypePaymentEnum.definir
      ) {
        actions.push({
          name: "Aprovar",
          action: handleApprove,
          icon: <Aprove />,
        });
      }
    }

    if (status === StatusTransactionEnum.changes_waiting_action && pending_price && pending_price > 0) {
      actions.push({
        name: "Escolher forma de pagamento",
        action: handleChoosePaymentMethodToPendingValues,
        icon: <MoneyGreen />,
      });
    }

    if (status === StatusTransactionEnum.changes_waiting_action && pending_price && pending_price < 0) {
      actions.push({
        name: "Escolher forma de reembolso",
        action: handleChoosePaymentMethodToPendingValues,
        icon: <MoneyOrange />,
      });
    }

    if (transactions.length > 0) {
      if (
        (status === StatusTransactionEnum.changes_waiting_action &&
          transactions[0].type === TypePaymentEnum.link) ||
        transactions[0].type === TypePaymentEnum.newlink
      ) {
        actions.push({
          name: "Gerar link",
          action: handleNewLink,
          icon: <Link />,
        });
      }

      if (status !== StatusTransactionEnum.cancelled) {
        actions.push({
          name: "Cancelar",
          action: handleCancel,
          icon: <CancelButton />,
        });
      }

      if (actions.length === 0) {
        actions.push({
          name: "Sem ações",
          action: () => {},
          icon: <FiAlertCircle />,
        });
      }
    }

    const pendingPrice = order.pending_price || 0;
    const paid_price = transactions.reduce(
      (total, transaction) =>
        total + (transaction.status !== "C" ? transaction.amount / 100 : 0),
      0
    );

    const originalPrice = Number(paid_price) + Number(pendingPrice);

    const appointmentsPaidPrice = order.appointments.reduce(
      (acc, appointment) => {
        const appointmentItemsPaidPrice = appointment.appointments_items.reduce(
          (acc, appointmentItem) => {
            return (acc += Number(appointmentItem.paid_price));
          },
          0
        );
        acc += Number(appointment.paid_price) + appointmentItemsPaidPrice;
        return acc;
      },
      0
    );

    const totalAppointmentsPrice = order.appointments.reduce(
      (acc, appointment) => {
        const appointmentItemsPaidPrice = appointment.appointments_items.reduce(
          (acc, appointmentItem) => {
            return (acc += Number(appointmentItem.item_price));
          },
          0
        );
        acc +=
          Number(appointment.service_price / 100) + appointmentItemsPaidPrice;
        return acc;
      },
      0
    );

    const total = convertToCurrencyFormat(originalPrice);

    const finalOrderValue = convertToCurrencyFormat(
      Number(originalPrice) + Number(total_discount <= 0 ? 0 : total_discount)
    );

    return {
      id,
      date: format(parseISO(created_at), "dd/MM/yyyy 'as' HH:mm:ss"),
      platform: stringOfEnum(PlatformEnum, platform),
      customer: user.name,
      cpf: applyMask(user.cpf, "cpf"),
      itens: appointments.length,
      paymentMethod:
        transactions.length > 0
          ? TypePaymentEnum[transactions[0].type]
          : "sem transação",
      appointmentsOrderValue: convertToCurrencyFormat(appointmentsPaidPrice),
      salesOrderValue: convertToCurrencyFormat(
        (sales_order?.total_price || 0) / 100
      ),
      finalOrderValue,
      total_discount: convertToCurrencyFormat(
        totalAppointmentsPrice - appointmentsPaidPrice
      ),
      total,
      status: status,
      actions,
      order: order,
    };
  };

  const loadOrders = async (wordSearch?: string) => {
    setData([]);
    setLoading(true);

    let where: any = {
      ...(wordSearch && { wordSearch }),
    };

    for (let filter of filters) {
      const { key, searchValue } = filter;

      switch (key) {
        case "Status":
          where.status = searchValue;
          break;

        case "CPF":
          where.cpf = removeSymbolsOfString(searchValue);
          break;

        case "Cliente":
          where.customer_name = searchValue;
          break;

        case "Forma de pagamento":
          where.type_payment = parseFloat(searchValue);
          break;

        case "Data Início":
          where.from = searchValue;
          break;

        case "Data Fim":
          where.to = searchValue;
          break;

        default:
          break;
      }
    }

    let orderBy = [];

    if (!!sort.field) {
      const { field, direction } = sort;

      switch (field) {
        case "date":
          orderBy.push({ date: direction });
          break;

        default:
          break;
      }
    }

    try {
      const results = (await searchOrders({
        pagination: { limit, page: currentPage },
        where,
        orderBy: [{ created_at: "desc" }],
      })) as any;

      const { orders, totalPages } = results;

      setTotalPage(totalPages);

      let data = orders.map((order: IOrder) => {
        return formatOrder(order);
      });

      setData(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleSeeOrderDetails = (id: string, data: IFormattedOrder[]) => {
    const selectedOrder = data.find((order) => order.id === id);
    setSelectedOrderDetails(selectedOrder);
    orderDetailsModalControl.onOpen();
  };

  const onSetPageSize = (pageSize: number) => {
    setLimit(pageSize);
  };

  const handleApplyFilter = async (f: any) => {
    setFilters(f);
    setCurrentPage(1);
  };

  const handleRemoveFilter = (key: string) => {
    let newFilters = filters.filter((f: any) => f.key !== key);
    setFilters(newFilters);
    loadOrders(newFilters);
  };

  const handleEdit = (id: string, orderData: any) => {
    let order_founded = orderData.find((d: any) => d.id === id);
    if (!order_founded) {
      toast.error("Erro ao editar pedido, pedido não encontrado!");
      return;
    }
    setSelectedOrder(order_founded.order);
    setModalVisible("edit_order");
  };

  const handleCancel = async (id: string, ordersData: any) => {
    let order_founded = ordersData.find((d: any) => d.id === id);
    if (!order_founded) {
      toast.error("Erro ao cancelar pedido, pedido não encontrado!");
      return;
    }
    setModalVisible("cancel_order");
    setSelectedOrder(order_founded.order);
  };

  const handleApprove = async (id: string, ordersData: any) => {
    let order_founded = ordersData.find((d: any) => d.id === id);
    if (!order_founded) {
      toast.error("Erro ao aprovar pedido, pedido não encontrado!");
      return;
    }
    if (!user.roles.find((role) => role.name === "financeiro" || "admin")) {
      toast.error("Você não tem permissão para aprovar pedidos!");
      return;
    }
    setSelectedOrder(order_founded.order);
    onOpen();
  };

  const handleApproveApply = async (id: string) => {
    if (!id) {
      toast.error("Erro ao aprovar pedido, pedido não encontrado!");
      return;
    }

    const order = await approveOrder(id);
    updateOrderList(order);
  };

  const updateOrderList = (order_updated: IOrder) => {
    setData(
      data.map((or: any) => {
        if (or.id === order_updated.id) {
          return formatOrder(order_updated);
        }
        return or;
      })
    );
  };

  const handleCancelApply = async (refund_type: RefundTypeEnum) => {
    if (!selectedOrder) {
      return;
    }

    try {
      setLoadingRefundValue(true);
      const order_updated = await cancelOrder({
        order_id: selectedOrder.id,
        refund_type: refund_type,
      });
      loadOrders('')
      setModalVisible("none");
      setSelectedOrder(undefined);
      launchToast("Pedido cancelado com sucesso", "success");
    } catch (error) {
      launchToast(processError(error, "GRAPHQL").message, "error");
    } finally {
      setLoadingRefundValue(false);
    }
  };

  const handleNewLink = async (order_id: string) => {
    setLoading(true);

    try {
      const response = await api.put<any>("/orders/change-link-payment", {
        order_id,
      });
      let newLink = response.data.transactions.find(
        (t: ITransaction) => t.status === StatusTransactionEnum.waiting
      )?.payload;
      navigator.clipboard.writeText(newLink);
      updateOrderList(response.data);
      setLoading(false);
      launchToast("Link copiado com sucesso", "success");
    } catch (error) {
      setLoading(false);
      launchToast("Houve erro na solicitação", "error");
    }
  };

  const handleChoosePaymentMethodToPendingValues = (
    order_id: string,
    ordersData: any
  ) => {
    const order_founded = ordersData.find((d: any) => d.id === order_id)
      .order as IOrder;
    setSelectedOrder(order_founded);

    if (!order_founded.pending_price) {
      launchToast("Contate a equipe tecnica", "error");
    } else if (order_founded.pending_price > 0) {
      setModalVisible("choose_payment_pending_values");
    } else if (order_founded.pending_price < 0) {
      setModalVisible("refunding");
    } else {
      launchToast("Contate a equipe tecnica", "error");
    }
  };

  const handleSubmitPaymentChange = async ({
    orderId,
    paymentType,
    cardId,
  }: {
    orderId: string;
    paymentType: number;
    cardId?: string;
  }) => {
    try {
      const response = await changePaymentTypeOfOrder(
        orderId,
        paymentType,
        cardId,
      );
        const transaction = response.transactions.find((t) =>
          [
            StatusTransactionEnum.changes_waiting_action,
            StatusTransactionEnum.waiting,
          ].includes(t.status as StatusTransactionEnum)
        );
      launchToast("Transacao criada com sucesso", "success");
      setModalVisible("none");
      await loadOrders("")
      const order_updated = response;
      if (transaction?.payload && transaction?.payload?.length > 0 ) {
        setLinkText(transaction.payload);
        linkModal.onOpen()
      }
      return order_updated;
    } catch (error) {
      launchToast('Erro ao selecionar o tipo de pagamento', "error");
    }
  };


  const handleSubmitPaymentPendingChanges = async ({
    orderId,
    paymentType,
    cardId,
    reason
  }: {
    orderId: string;
    paymentType: number;
    cardId?: string;
    reason?: string
  }) => {
    try {
      const response = await paymentApi.post('/transactions/createFromPendingValues', {
        orderId,
        paymentType,
        cardId,
        reason
      });
      setModalVisible("none");
      launchToast("Transacao criada com sucesso", "success");
      await loadOrders("")
      const order_updated = response.data;
      if (!(response.data.url.length <= 0 )) {
        setLinkText(response.data.url);
        launchToast("Aguarde um momento para copiar o link", "info");
        linkModal.onOpen()
      }
      return order_updated;
    } catch (error) {
      launchToast('Erro ao selecionar o tipo de pagamento', "error");
    }
  };

  const handleRefundPendingValue = async (refund_type: RefundTypeEnum) => {
    if (!selectedOrder) {
      setSelectedOrder(undefined);
      launchToast("Erro desconhecido", "error");
      setModalVisible("none");
      return;
    }

    try {
      setLoadingRefundValue(true);
      const order = await refundPendingValues(selectedOrder.id, refund_type);
      loadOrders("")
      launchToast("Estorno aplicado com sucesso", "success");
      setModalVisible("none");
      setData([]);
      return order;
    } catch (error) {
      launchToast(processError(error, "GRAPHQL").message, "error");
    } finally {
      setLoadingRefundValue(false);
    }
  };

  const handleWordSearch = (event: string) => {
    setCurrentPage(1);
    setWordSearch(event);
  };

  useEffect(() => {
    setTimer(
      setTimeout(function () {
        loadOrders(wordSearch);
      }, 500)
    );
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [wordSearch, currentPage, limit, filters, sort]);

  return (
    <>
      {loading && <Loading></Loading>}

      <MainContainer>
        <PanelHeader
          title="Pedidos"
          onClick={() => setModalVisible("filtering")}
          isFilterModalVisible={true}
        />
        <Search
          placeholder="Pesquisar por nome, cpf"
          wordSearch={wordSearch}
          setSearch={handleWordSearch}
          isFilterForRegister={true}
          onSetPageSize={onSetPageSize}
        />
        {filters.length >= 1 && filters[0].key !== "created" && (
          <FilterList filters={filters} removeFilter={handleRemoveFilter} />
        )}
        <Table headers={headers} data={data} />
        <Pagination
          totalPage={totalPage}
          pageIndex={currentPage}
          setPage={setCurrentPage}
        />
      </MainContainer>
      {selectedOrderDetails && (
        <OrderDetailsModal
          isOpen={orderDetailsModalControl.isOpen}
          onClose={orderDetailsModalControl.onClose}
          order={selectedOrderDetails}
        />
      )}
      { !loading && <LinkModal
          isOpen={linkModal.isOpen}
          onClose={linkModal.onClose}
          link={linkText}
      />}
      {modalVisible === "filtering" && (
        <FilterModal
          visible={modalVisible === "filtering"}
          onCloseModal={() => setModalVisible("none")}
          applyFilters={handleApplyFilter}
          reciviedFilter={filters}
        />
      )}

      {modalVisible === "edit_order" && (
        <ChoosePaymentPendingModal
          visible={modalVisible === "edit_order"}
          order={selectedOrder}
          onCloseModal={() => setModalVisible("none")}
          onSubmit={handleSubmitPaymentChange}
          paymentStatus='W'
        />
      )}

      {modalVisible === "choose_payment_pending_values" && (
        <ChoosePaymentPendingModal
          visible={modalVisible === "choose_payment_pending_values"}
          order={selectedOrder}
          onCloseModal={() => setModalVisible("none")}
          onSubmit={handleSubmitPaymentPendingChanges}
          paymentStatus= 'U'
        />
      )}

      {modalVisible === "refunding" && (
        <ChooseRefundTypeModal
          visible={modalVisible === "refunding"}
          onCloseModal={() => setModalVisible("none")}
          onSubmit={(refund_type) => handleRefundPendingValue(refund_type)}
          loading={loadingRefundValue}
        />
      )}

      {modalVisible === "cancel_order" && (
        <ChooseRefundTypeModal
          visible={modalVisible === "cancel_order"}
          onCloseModal={() => setModalVisible("none")}
          onSubmit={(refund_type) => handleCancelApply(refund_type)}
          loading={loadingRefundValue}
        />
      )}
      {
        <ModalConfirmation
          isOpen={isOpen}
          onClose={onClose}
          handleConfirm={() => handleApproveApply(selectedOrder?.id || "")}
          nameAction={`Aprovar pedido`}
        />
      }
    </>
  );
};
export default Orders;
