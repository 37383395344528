import React, { useEffect, useRef, useState } from 'react';
import * as Styles from '../../constants/styles';
import { ICreateReceiptsFormData, IProductReceipts, ISupplierReceipt } from 'pages/Receipts/constants/types';
import { Label } from 'components/Label/styles';
import InputRef from "components/InputRef";
import { Form } from "@unform/web";
import Select from 'components/Select';
import { FormHandles } from '@unform/core';
import { launchToast } from 'shared/utils/launchToast';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';
import { CreateReceiptFormValidationSchema } from './CreateFormValidations';
import { listOptionSupplier } from 'pages/Stock/graphql/StockQuery';

export type ICreateReceiptsFormProps = {
    onSubmit: (payload: ICreateReceiptsFormData, options: { reset: () => void }) => Promise<void>,
    products: IProductReceipts[],
}

export const CreateReceiptsFormId = 'create-receipts-form';


const CreateReceiptsForm: React.FC<ICreateReceiptsFormProps> = ({
    onSubmit,
    products
}) => {

    const [searchSupplierInput, setSearchSupplierInput] = useState('')
    const [supplierOptions, setSupplierOptions] = useState([] as any)
    const [selectedSupplier, setSelectedSupplier] = useState([] as any)
    const [supplierError, setSupplierError] = useState("");

    const createReceiptsFormRef = useRef<FormHandles>(null);


    const formatDateTime = (): string => {
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    useEffect(() => {
        let timeoutSupplier: NodeJS.Timeout;
        const fatchSupplier = async () => {
            const supplierList = await listOptionSupplier(searchSupplierInput)
            setSupplierOptions(supplierList);
        }
        const delayedFetchData = () => {
            clearTimeout(timeoutSupplier);
            timeoutSupplier = setTimeout(fatchSupplier, 400);
        };

        delayedFetchData();

        return () => clearTimeout(timeoutSupplier);
    }, [searchSupplierInput]);

    useEffect(() => {
        createReceiptsFormRef.current?.setData({
            emission_date: formatDateTime()
        });
    }, []);

    useEffect(() => {
        if (selectedSupplier.value) { setSupplierError("") }
    }, [selectedSupplier]);

    const normalizeDate = (date: string) => {
        const originalDate = new Date(date);
        const newDate = new Date(originalDate.getTime() + 3 * 60 * 60 * 1000);

        return newDate.toISOString();
    }

    const handleSubmit = async (
        payload: ICreateReceiptsFormData,
        options: { reset: () => void }
    ) => {
        try {
            if (products.length === 0) {
                launchToast("Numero mínimo de produtos na nota fiscal é 1", "error");
            } else {
                await CreateReceiptFormValidationSchema.validate({ ...payload, number: payload.number, supplier_id: selectedSupplier, products: products }, { abortEarly: false });
                onSubmit({ ...payload, emission_date: normalizeDate(payload.emission_date), number: Number(payload.number), supplier_id: selectedSupplier, products: products }, options);
                setSelectedSupplier([] as any)
            }
        } catch (error) {
            const errors = getValidationErrors(error as ValidationError);
            createReceiptsFormRef.current?.setErrors(errors);
            if (!selectedSupplier.value) { setSupplierError("Selecione um fornecedor") }
            launchToast("Verifique o preenchimento dos dados", "error");
        }
    };

    return (
        <>
            <Form
                onSubmit={handleSubmit} ref={createReceiptsFormRef} id={CreateReceiptsFormId}
            >
                <Styles.row>
                    <Styles.field>
                        <Label>Numero</Label>
                        <InputRef
                            name="number"
                            type='number'
                            placeholder="Digite o numero da NF"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Data de Emissão</Label>
                        <InputRef
                            containerStyle={{ width: "33rem" }}
                            name="emission_date"
                            type="date"
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Marca</Label>
                        <Select
                            name="brand"
                            options={supplierOptions?.map((brand: ISupplierReceipt) => ({
                                ...brand,
                                label: brand.name
                            })) as { value: string; label: string; }[]}
                            onInputChange={setSearchSupplierInput}
                            error={supplierError}
                            placeholder="Busque uma marca"
                            onChange={(e) => setSelectedSupplier(e)}
                        />
                    </Styles.field>
                </Styles.row>
            </Form >
        </>
    )
}

export default CreateReceiptsForm